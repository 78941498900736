.download-report-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 28px;
}
.download-report-container-bottom-gap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.ux-balance .cta[class*="report-spacing"] {
    padding-left: 2px !important;
    padding-right: 2px !important;
}
.gap-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}