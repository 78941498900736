@use '../01_settings/config.scss' as *;

.width-258 .width-258.input-box {
    width: 258px;
}

.exception-card-title {
    margin-bottom: 10px;
}

.exception-card {
    padding-right: 40px;
    //padding-right: 90px;
}

.exception-form-controls {
    margin-bottom: 24px;
}

.additional-payment-details:not(:last-child) {
    margin-right: 48px;
}

.additional-payment-textareas {
    margin-bottom: 16px;
}

.additional-payment-delete {
    grid-column: 1 / -1;
    margin-top: 32px;
}

.exceptions-image-card {
    width: 70%;
}

.additional-payment-add {
    margin: 40px 0 0 0;
}

.ux-balance .form-control__container.width-255, .width-255 {
    width: 255px;
}

.bottom-gap-22 {
    margin-bottom: 22px;
}

.exception-transaction-submit {
    margin: 40px 0 8px 0;
}

.exception-data-text {
    @include text-style-body-2;
    font-weight: 600;
}

.custom-pagination-container {
    margin-bottom: 80px;
}