@use '../01_settings/config.scss' as *;

@mixin texts {
  @include text-style-body-4;
  color: $color-gray-50;
  font-weight: 600;
}

.breadcrumbs .parent a,
.breadcrumbs .parent button {
  @include texts;

  &:hover {
    border-bottom: 1px solid $color-gray-50;
  }
}

.parent .slash {
  @include texts;
  margin: 0 8px;
}

.breadcrumbs .current {
  @include text-style-body-4;
  color: $color-gray-90;
  font-weight: 600;
}