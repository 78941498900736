@use '../01_settings/config.scss' as *;

.tabs-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  margin-bottom: 100px;
}

.custom-tabs {
  padding: 12px 24px;
  text-align: center;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  color: $color-gray-50;
  border-bottom: 4px solid #e8e8e8;
  position: relative;
  outline: none;

  &:hover {
    color: #417514;
    background-color: #F0FAD4;
  }

  &:focus {
    color: #1D4900;
    background-color: #F0FAD4;
    border: 2px solid #5E9C11;
    border-bottom: 4px solid #e8e8e8;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.active-tabs {
  border-bottom: 4px solid $color-green-40;
  color: $color-gray-90;

  &:focus {
    border: none;
    border-bottom: 4px solid $color-green-40;
    color: $color-gray-90;
    background-color: none;
  }
}

.content {
  background: white;
  padding: 32px 0 0;
  width: 100%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
}

.tabs-content {
  flex-grow: 1;
}

.tabs-header {
  display: flex;
}

.list_border {
  border: 1px solid $color-green-40;
}

.top-gap-40 {
  padding-top: 40px;
}

.exception-tabs {
  margin-bottom: 8px !important;
}

.ux-balance .tab-list {
  overflow-x: auto;
}

.ux-balance .tab-panel {
  padding: 40px 0 40px 0;
}

.exception-tabs .tabs {
  border-bottom: none !important;
}

.tab-panel-inner-contents {
  text-align: left;
}