@use '../01_settings/config.scss' as *;

@mixin texts {
    @include text-style-body-2;
    color: $color-gray-90;
    font-weight: 600;
}

.banker-reporting-summary {
    margin: 30px 0 40px 5px;
}

.reporting-summary-button {
    margin-top: 32px;
}

.lockbox-summary-table {
    margin-bottom: 150px;
}

//exception summary

.exception-summary-heading {
    margin-bottom: 40px;
}

.exception-search-control,
.exception-report-control {
    display: flex;
    margin: 0 0 40px 4px;
}

.exception-summary-search-button {
    margin: 24px 24px 0;
}

.light-divider {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    margin: 40px 0;
}

.top-gap-8 {
    margin-top: 8px;
}

.corp-code-title {
    @include texts;
    margin-bottom: 24px;
}

.exception-reports-title {
    @include texts;
    margin-bottom: 16px;
}

.ux-balance .form-control__container.width-242, .width-242 {
    width: 242px;
}