.banner-list {
    margin: 0.25em 0 0 0;

    .banner-item:not(:first-child) {
        margin-top: 1em;
    }
}

.banner-margin-top {
    margin-top: 2em;
}

.banner-create-edit-container {
    max-width: 668px;

    .form-control {
        margin: 2rem 0rem;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.5rem;

        .item {
            width: 322px;
            box-sizing: border-box;
        }
    }
}

.banner-return-button {
    padding-left: 0 !important;
}

.heading-title-banners {
    margin: 32px 0;
}

.item {
    .width-208.input-box {
        width: 322px;
    }

    .input-box input {
        width: 322px;
    }

    .calendar-label {
        margin-bottom: 8px;
    }
}