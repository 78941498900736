@use '../01_settings/config.scss' as *;

.exception-setup-title {
    @include text-style-heading-4;
    margin: 32px 0 40px 0;
}

.ux-balance .form-control__container.width-412,
.width-412 {
    width: 412px;
}

.ux-balance .form-control__container.width-92,
.width-92 {
    width: 92px;
}

.ux-balance .form-control__container.width-124,
.width-124 {
    width: 124px;
}

.ux-balance .form-control__container.width-478,
.width-478 {
    width: 478px;
}

.exception-setup-section {
    display: flex;
    margin-top: 16px;
}

.exception-setup-title {
    @include text-style-heading-4;
    margin: 32px 0 40px 0;
}

.selection-list-title {
    @include text-style-body-2;
    color: $color-gray-90;
    font-weight: 600;
    margin: 32px 0 16px 0;
}

.exception-setup-gap {
    margin-right: 115px;
}

.exception-tabs .tabs {
    padding-left: 0;
    padding-right: 0;
}

.exception-setup-content-table table.table {
    width: 55%;
    max-width: 900px;
    border: 1px solid #D5D5D5;

    @media screen and (min-width: 1201px) and (max-width: 1600px) {
        width: 80%;
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.exception-setup-content-table .table__pagination {
    width: 55%;
    max-width: 900px;

    @media screen and (min-width: 1201px) and (max-width: 1600px) {
        width: 80%;
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.exception-setup-content-table thead tr th:first-child {
    width: 145px;
}

th, td {
    color: $color-gray-90;;
}