@use '../01_settings/config.scss' as *;

.data-title {
    @include text-style-body-3;
    padding-top: 16px;
}

.data-text {
    @include text-style-body-1;
    font-weight: 600;
}

.additional-card-parent {
    display: flex;
    flex-direction: column;
}

.additional-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.card {
    padding-right: 90px;
}

.additional-details-container {
    display: flex;
    flex-wrap: wrap;

}

.additional-details {
    box-sizing: border-box;
    margin-bottom: 1px;
}

.additional-details:not(:last-child) {
    margin-right: 72px;
}

.additional-details-accordions {
    margin-top: 26px;

    .accordion__title {
        @include text-style-body-2;
    }

    .accordion__header .accordion__button--heading-4 {
        padding: 1rem 2rem !important;
    }

    .accordion__title.is-expanded {
        @include text-style-body-2;
        font-weight: 600;
    }
.accordion_pane_content {
    display: grid;
    grid-template-columns: repeat(4, 25%); //repeat(4, 300px);
    grid-auto-rows: min-content;
    width: (4 * 300px + 3 * 50px);
    column-gap: 48px;
    row-gap: 16px;

    @media screen and (max-width: 1600px) {
        grid-template-columns: repeat(3, 33%);
        width: (3 * 300px + 2 * 50px);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 50%);
        width: (2 * 300px + 1 * 50px);
    }

    @media screen and (max-width: 425px) {
        grid-template-columns: 1fr;
    }
}
}