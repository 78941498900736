@use '../01_settings/config.scss' as *;

.lockbox-search-control {
    display: flex;
    margin-left: 4px;
}

.ux-balance .form-control__container.lockbox-search-input-208 {
    width: 208px;
}

.ux-balance .form-control__container.lockbox-search-input-322 {
    width: 322px;
}

.lockbox-search-button {
    margin-left: 24px;
    margin-top: 32px;
}

.lockbox-search-title {
    @include text-style-heading-4;
    margin: 32px 0;
}
.lockbox-search-zero-state {
    margin-top: 24px;
}