@use '../01_settings/config.scss' as *;
.search-fields-container {
    display: flex;

    .search-text-input-226 {
        width: 226px;
    }

    .search-text-input-214 {
        width: 214px;
    }

    .search-text-input-212 {
        width: 212px;
    }

    .search-text-input {
        width: 208px;
    }
}

@mixin title {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: $font-family;
    color: $color-gray-90;
}

.search-title {
    @include title;

    &__non-transaction-detail {
        margin-bottom: 24px;
    }
}

.column-section-title {
    @include title;
}

.top-gap-24 {
    padding-top: 24px;
}

.ux-balance .form-control__container.width-226, .width-226 {
    width: 226px;
}

.ux-balance .form-control__container.width-322, .width-322 {
    width: 322px;
}

.ux-balance .form-control__container.width-236, .width-236 {
    width: 236px;
}

.width-226 .width-208.input-box {
    width: 226px;
}
.report-actions {
    display: flex;
    align-items: center;
    
    button {
        padding-left: 2px !important;
    }
}

.action-separator {
    color: #8C8C8C;
    margin: 0 12px 0 12px;
}

.transaction-detail-section {
    display: flex;
    margin-top: 24px;
}

.customize-section {
    width: 50%;
}

.column-section {
    display: flex;
    margin-top: 24px;
}

.column-separator {
    margin-top: 32px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.display-block {
    display: block;
}

.search-fields-container .input-name-box, .search-fields-container .input-value-box {
    width: 450px;
}

.transaction-detail-label {
    display: flex;
    align-items: center;
    margin-top: 32px;
    width: 150px;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: $font-family;
    color: $color-gray-90;
}

.additional-options-checkboxes {
    margin-bottom: 10px;
}

.search-and-report-title {
    @include text-style-heading-4;
    margin: 32px 0 12px 0;
}

.download-search-and-Reports {
    margin-top: 0 !important;
}

.refresh-container {
    margin-top: 4px !important;
    margin-bottom: -32px !important;
}

.view-results-table {
    min-height: 400px;
}