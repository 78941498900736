.unfavorite-links, .favorite-links {
    padding: calc(0.25em - 0.125rem) calc(1.6em - 12px);
}

.top-gap-32 {
    padding-top: 32px;
}

.heading-reporting-summary {
    margin: 6px 0 0 6px;
}