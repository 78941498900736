@use '../01_settings/config.scss' as *;

.go-back-buttons-container {
    margin-bottom: 40px;
}
.ux-balance .form-control__container.width-450, .width-450 {
    width: 450px;
}
.ux-balance .form-control__container.width-438, .width-438 {
    width: 438px;
}
.ux-balance .form-control__container.width-322, .width-322 {
    width: 322px;
}
.ux-balance .form-control__container.width-300, .width-300 {
    width: 300px;
}
.ux-balance .form-control__container.width-206, .width-206 {
    width: 206px;
}
.ux-balance .form-control__container.width-222, .width-222 {
    width: 222px;
}