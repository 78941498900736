@use '../01_settings/config.scss' as *;

.exceptions-mainheading {
    margin: 0 0 16px 6px;
}

.daily-summary, .exceptions-subheading {
    @include text-style-body-1;
    
    display: flex;

    p {
        margin-left: 40px;

        &:first-child {
            margin-left: 0;
        }
    }
}