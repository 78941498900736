@use '../01_settings/config.scss' as *;
.card-container {
    box-shadow: 0px 1px 4px 0px #00000029;
    border: 1px solid #D5D5D5;
}
.inner-container {
    display: flex;
}
.card {
    width: 20%;
}
.image-card {
    width: 79%;
}
.related-documents-container .image-card {
    width: 98%;
}
.card-title {
    @include text-style-heading-4;
}
.card-divider {
    border-top: 1px solid $color-gray-30;
    height: 0;
    margin: 40px 0 40px;
  }
.aditional-card-row {
    display: flex;
    justify-content: space-between;
}
.pagination-container {
    display: flex;
    margin-top: 16px;
    padding-bottom: 4px
}
.additional-details-note {
    @include text-style-body-2;
}
.transaction-tile-container {
    padding: 1px;
}

.hide-block {
    display: none;
}