
@use '../01_settings/config.scss' as *;

.header-pipe {
  margin: 0 16px 0 0;
}

svg.regions-logo {
  margin-right: 16px;
}

.pipe {
    margin: 0 16px;
}

.ux-balance .error-summary {
  margin-bottom: 24px;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.announcement-text {
  position: absolute;
  top: -1000px;
}

.zero-state {
  color: $color-gray-50;
  font-size: 1.25rem;
  font-weight: 600;
}

.info-text {
  &__18 {
    @include text-style-body-2;
  }
  &__20 {
    @include text-style-body-1;
  }
}

.strong-text {
  font-weight: 600;
}

.text-gray-90 {
  color: $color-gray-90;
}

.tabs-content-input-labels .form-control__label-content-wrapper {
  //font-weight: 600;
  color: $color-gray-90;
}

.table-action-buttons .cta--small {
  padding-left: 0 !important;
}