.ux-balance .form-control__container.width-322, .width-322 {
    width: 322px;
  }

.correspondence-title {
    margin-bottom: 12px;
    margin-top: 28px;
}

.filter-title {
    margin-top: 36px;
}

.correspondence-zero-state {
    margin-top: 24px;
}