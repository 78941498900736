@use '../01_settings/config.scss' as *;
@use '~@ux/balance-tokens/build/tokens.scss' as *;

.container_gap {
    margin-right: 40px;
    margin-left: 40px;
}
.gap-32-vertical {
    margin-top: $space-70;
    margin-bottom: $space-70;
}