@use '../01_settings/config.scss' as *;

.filter-main-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px
}
.filter-search-container {
  display: flex;
  justify-content: space-between;
}
.filter-title {
  @include text-style-heading-4;
  margin-bottom: $space-50;
}
.filter-container {
  display: flex;
  justify-content: space-between;
}
.download-dropdown-container {
  display: flex;
}
.left-24-gap-filters {
  margin-left: $gap-left-24;
}
.left-8-gap-filters {
  margin-left: $gap-left-8;
}
.top-gap-24 {
  padding-top: $gap-top-24;
}
.top-gap-32 {
  padding-top: $gap-top-32;
}
.temp-list {
  border: 1px solid $color-green-50;
  padding-right: 10px;
  margin: 4px 0 2px;
}
// .zero-state {
//   color: $color-gray-50;
// }
.ux-balance .form-control__container.width-152, .width-152 {
  width: 152px;
}
.ux-balance .form-control__container.width-208, .width-208 {
  width: 208px;
}
.ux-balance .form-control__container.width-264, .width-264 {
  width: 262px;
}
.text-selection {
  margin-bottom: 8px;
}

.batch-summary-search, .batch-detail-search {
  display: flex;
  justify-content: space-between;

  .title {
    @include text-style-heading-5;
  }

  .sub-title {
    @include text-style-body-3;
    font-weight: 600;
  }

  .sub-title.checkbox-groups {
    margin-bottom: 1rem;
  }

  .radio-groups {
    margin-bottom: 2rem;
  }

  .download-button {
    width: 108px;
    //padding-left: 0;
  }
}

.bottom-gap-10 {
  margin-bottom: 10px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.top-gap-66 {
  margin-top: 66px;
}

.ux-balance .cta--alternate.highlighted-active {
  border: none;
}
.ux-balance .cta--alternate.highlighted-active:focus:not(:focus-visible) {
  border: 4px solid 	$color-secondary-teal-light;
}

.dropdown-divider {
  border-top: 1px solid #D5D5D5;
    height: 0;
    margin: 20px 0 20px;
}