@use '../01_settings/config.scss' as *;

.ux-balance .cta--text-primary.star-color {
    color: $color-green-50;
}

.center-link {
    padding-left: 2rem;
}

table.table {
    width: 100%;
}

.ux-balance .table th,
.ux-balance .table td {
    @media screen and (max-width: 1300px) {
        padding: 1rem 0.5rem !important;
    }

    @media screen and (min-width: 1301px) and (max-width: 1500px) {
        padding: 1rem 0.8rem !important;
    }
    @media screen and (min-width: 1501px) {
        padding: 1rem 1rem !important;
    }
}

.responsive-table * {
    font-size: 1rem !important;
}

.ux-balance .table__header--active {
    background: $color-green-50 !important;
}

.table-select-all  {
    display: flex;
    align-items: center;

    span {
        padding-right: 12px;
    }
}

.batch-detail-table  .review-items {
    line-height: 0.25;
}

.center-select {
    padding-left: 1rem;
}

.ux-balance .table__status, .ux-balance .table__supplemental-status {
    margin-bottom: 8px;
}

.table-row-links {
    text-decoration: underline;
    text-decoration-thickness: $link-text-decoration-thickness;
    text-underline-offset: $link-text-underline-offset;
 
    &:focus {
      text-decoration: none;
    }
  }

  .ux-balance .vertically-ruled-table td:nth-child(odd),
  .ux-balance .vertically-ruled-table th:nth-child(odd) {
      border-right: none;
  }

  .ux-balance .vertically-ruled-table td:nth-child(even) {
      border-right: 1px solid #d5d5d5;
  }

  .ux-balance .vertically-ruled-table th:nth-child(even) {
      border-right: 1px solid #fff;
  }

  .ux-balance button.underlined-links {
    padding-left: 0 !important;

    &:focus, &:hover {
        text-decoration: none;
      }
  }

  .ux-balance .underlined-links span {
    text-decoration: underline;

    &:focus, &:hover {
        text-decoration: none;
      }
  }