@use '../01_settings/config.scss' as *;

.disable-link-pagination-list .pagination__prev-next--display{
    display: none;
}

.pagination-styles {
    a:not([disabled]){
        cursor: pointer;
    }

    .pagination__ellipses a.break-text {
        text-decoration: none;
        cursor: default;
        color: inherit;
    }

    .pagination__ellipses a.break-text:hover {
        text-decoration: none;
        cursor: default;
    }
}

.page-next-icon {
    margin-left: 12px;
}

.page-previous-icon {
    margin-right: 12px;
}

.go-to-text {
    @include text-style-body-3;
    color: $color-gray-50;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.image-viewer-pagination {
    display: flex;
    justify-content: flex-end;
    
    .pagination-styles a {
        color: $color-gray-50 !important;
    }

    .pagination-styles a:hover:not(.cta--is-loading):not(:disabled) {
        color: #004964 !important;
    }

    .pagination-styles a:active {
        color: #fff !important;
    }

    .pagination-styles a:focus:not(:focus-visible) {
        color: #fff !important;
    }

    .pagination-styles a.cta--active {
        color: #fff !important;
    }

    * {
        font-size: 0.875rem;
    }

}