@use '../01_settings/config.scss' as *;

@mixin title {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: $font-family;
    color: $color-gray-90;
}

.search-client-title,
.add-client-title {
    @include title;
    margin-bottom: 24px;
}

.heading-title-client {
    margin-bottom: 12px;
}

.vertical-gap-24 {
    margin: 24px 0;
}

.title-left-spacing h2 {
    padding: 0 3rem;
    text-align: left !important;
}

.ux-balance .form-control__container.width-208,
.width-208 {
    width: 208px;
}

.ux-balance .form-control__container.width-322,
.width-322 {
    width: 322px;
}

.bottom-gap-6 {
    padding-bottom: 6px;
}