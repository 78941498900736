@use '../01_settings/config.scss' as *;

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: $color-green-40 !important;
}

.input-box * {
    color: $color-gray-90;
    font-family: $font-family;
    font-size: 1rem;
}

.react-datepicker {
    border: 1px solid #8c8c8c;
    border-radius: 8px;

    .react-datepicker__header {
        border-radius: 8px 8px 0 0;
    }
}

.react-datepicker__day-names {
    font-weight: 600;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: #ccc;
}

.input-box input {
    //margin-top: 8px;
    height: 48px;
    padding: 0.5rem 1rem;
    background-color: #fff;
    cursor: pointer;
    line-height: 1.25;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: $font-family;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.008em;
    line-height: 1.5;
    appearance: none;
    border-color: #8c8c8c;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    width: 100%;

    &:hover:not(:disabled) {
        border-color: $color-green-40;
    }

    &:active,
    &:focus:not(:disabled) {
        box-shadow: 0 0 0 4px $color-green-40;
        border-color: $color-green-50;
        outline: none;
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $color-green-40;
    color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #bad9f1;
}

.calendar-label {
    font-family: $font-family;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.008em;
    line-height: 1.5;
    font-weight: 600;
    display: flex;
    flex-direction: row;

    @media screen and (min-width: 376px) {
        font-size: 0.9375rem;
    }

    @media screen and (min-width: 1024px) {
        font-size: 1rem;
    }
}

.react-datepicker__header {
    background-color: $color-gray-10;
    border-bottom: 1px solid $color-gray-40;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background-color: $color-gray-10;
}

.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--select {
    padding: 12px 0 8px 0;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    border-radius: 6px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    padding-top: 10px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: $color-gray-10;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: $color-gray-50;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: 600;
}

.calendar-date-picker .react-datepicker-popper {
    transition-duration: 0ms !important;
}

.calendar-date-picker {
    position: relative;
}

.calender-label {
    display: flex;

    .calender-label-popover {
        position: relative;
        top: -4px;
    }
}

.calender-icon {
    position: absolute;
    top: 12px;
    z-index: 1;
    right: 12px;
    cursor: pointer;
}

.calender-icon svg {
    color: $color-teal-50 !important;
}

.calender-label .popover svg {
    color: $color-gray-60 !important;
}

.calender-label .popover button {
    padding: 8px;
}

.input-box .cta__icon-wrapper * {
    color: $color-gray-50;
}

.align-popover-label-start-date {
    margin-bottom: 8px;
}

.align-popover-end-date {
    margin-top: -2px;

    .calender-label p {
        margin-right: 0.5rem;
    }
}

.error-label p {
    color: #be1e18;
}

.react-datepicker-wrapper {
    width: 100%;
}

.input-box input.input-date-error {
    border-color: #dd3721;
    margin-bottom: 8px;
}

.input-box input.input-date-error:hover:not(:disabled) {
    border-color: #9f1016;
}

.input-box input.input-date-error:focus:not(:disabled) {
    box-shadow: 0 0 0 4px #ea7153;
    border-color: #9f1016;
}