@use '../01_settings/config.scss' as *;

.exception-batch-detail-filter-container {
    margin: 0 0 16px 4px;
}

.exception-batch-detail-filter {
    .dropdown-menu__card--right {
        left: 0 !important;
    }
}

.exception-batch-detail-filter {
    width: auto;
}

.ux-balance .form-control__container.width-272, .width-272 {
    width: 272px;
  }

.batch-data-container {
    min-height: 700px;
}
.filter-status-label {
    @include text-style-heading-4;
}
.filter-status-checkboxes .form-control__label-content-wrapper {
    font-weight: 400;
}