@use '../01_settings/config.scss' as *;

@mixin title {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: $font-family;
    color: $color-gray-90;
}

.help-title {
    @include title;
    padding-bottom: 20px;
}

.help-link {
    padding: 20px 0 0 0 !important;
}

.help-card-link {
    padding: 0 0 20px 0 !important;
}

.help-texts {
    @include text-style-body-1;
}

.bottom-gap-20 {
    padding-bottom: 20px;
}

.faq {
    &-title {
      @include text-style-body-2;
      color: $color-primary-gray-dark;
      font-weight: 600;
      margin-bottom: 16px;
    }

    &-text {
      @include text-style-body-1;
      color: $color-primary-gray-dark;
      margin-bottom: 32px;
    }

    &-li-text {
        @include text-style-body-1;
        color: $color-primary-gray-dark;
        margin-bottom: 16px;
      }
  }

  .faq-helplink {
    padding: 1px 0 0 0 !important;
  }