@use '../01_settings/config.scss' as *;

.heading-focus-text {
    display: inline-block;
}

.heading-focus-text:focus {
    border-radius: $cta-text-base-border-radius;
    outline: 1px dashed $color-gray-60;
    outline-offset: $space-20;
}