@use '../01_settings/config.scss' as *;

/* dropdown styles */
.dropdown-container {
    display: flex;
    justify-content: space-between;
}
/* navigation tabs styles */
.tabs-container {
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 800; */
}

.tabs {
    padding: 12px 28px;
    text-align: center;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    color: #707070;
    position: relative;
    outline: none;
}

.active-tabs {
    border-bottom: 3px solid #5E9C11;
    color: #111111;
}

.content {
    background: white;
    padding: 12px;
    width: 100%;
    height: 100%;
    display: none;
}

.active-content {
    display: block;
}

.tabs-content {
    flex-grow: 1;
}

.tabs-header {
    display: flex;
}

.list_border {
    border: 1px solid #5E9C11;
}
/* Image Lockbox with logo in header */
.image-lockbox {
    margin-left: 12px;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.header-add {
    justify-content: flex-start !important;
}

.serach_and_dropdown {
    display: flex;
    align-items: center;
}

.search-container {
    display: flex;
    margin-right: 32px;
}

.lockbox-name {
    padding-top: 24px;
}
/* timestamp in header */
.timestamp-container {
    display: flex;
    justify-content: flex-end;
}

.timestamp-breadcrumbs-container {
    display: flex;
    justify-content: space-between;
}

.test {
    @include text-style-body-1;
    color: $color-green-50;
}

.menu-devider-header {
    border-top: 1px solid #979797;
    height: 0;
    margin: 12px 0 6px;
}
.time-stamp {
    margin-top: 6px;
}

.vl {
    border-left: 1px solid #000;
    margin: 0em 0em 0em 1px;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-grow {
    flex-grow: 2;
}

svg.regions-logo {
    margin-right: 1em;
}
