@use '../01_settings/config.scss' as *;

.ux-balance .form-control__container.width-554,
.width-554 {
    width: 554px;
}

.bottom-gap-10 {
    padding-bottom: 10px;
}

.bottom-gap-24 {
    padding-bottom: 24px;
}

.bottom-gap-20 {
    padding-bottom: 20px;
}

.bottom-gap-52 {
    padding-bottom: 52px;
}

.width-card-divider {
    width: 50%;
}
.report-actions .action-icon {
    padding-top: 0.2rem;
}
.alerts-modals {
    margin-bottom: 8px;
}
.current-alerts-container div.alerts-modals div div {
line-height: 0;
}