@use '../01_settings/config.scss' as *;

.heading-title {
    @include text-style-heading-4;
    margin: 32px 0;
}

.section-title,
.table-title {
    font-size: 1.25rem;
    font-weight: 600;
    font-family: $font-family;
    color: $color-gray-90;
    margin-bottom: 16px;

    &--info{
        margin-bottom: 8px;
    }
}

.mange-buttons-container-actions {
    margin-bottom: 8px;
}

.mange-buttons-container {
    margin-bottom: 32px;
}

.input-box-container {
    display: flex;
    margin-bottom: 24px;

    .input-box {
        &__xlarge {
            width: 438px;
        }

        &__large {
            width: 322px;
        }

        &__medium {
            width: 208px;
        }

        &__small {
            width: 92px;
        }
    }
}

.switch-box-container-banker {
    display: block;

    .switch-box:not(:last-child) {
        margin-bottom: 16px;
    }
}

.switch-box-container-client {
    display: flex;
}

.switch-box {
    display: flex;
    @include text-style-body-2;
    margin-top: 4px;

    .left-16-gap {
        margin-left: 16px;
    }

    .top-4-gap {
        padding-top: 3px;
    }
}

.rigth-98-gap {
    margin-right: 198px;
}

.top-30-gap {
    margin-top: 32px;
}

.top-16-gap {
    margin-top: 16px;
}

.create-edit-fields {
    margin-left: 4px;
}

.checkbox-group .form-control__input--checkbox-large+.form-control__label-wrapper .form-control__label {
    font-weight: 400 !important;
}

.user-management-table table.table { 
    width: 40%;
    border: 1px solid #D5D5D5;
    @media screen and (min-width: 1201px) and (max-width: 1600px) {
        width: 65%;
    }
    @media screen and (max-width: 1200px) {
        width: 75%;
    }
    @media screen and (max-width: 768px) {
       width: 100%;
    }
}

.user-management-table .table__pagination {
    width: 40%;
    @media screen and (min-width: 1201px) and (max-width: 1600px) {
        width: 65%;
    }
    @media screen and (max-width: 1200px) {
        width: 75%;
    }
    @media screen and (max-width: 768px) {
       width: 100%;
    }
}

.user-management-table thead tr th:first-child {
    width: 140px;
}

.user-management-table .center-select {
    min-width: 300px;
    padding-left: 100px;
}
.go-back {
    padding-left: 0 !important;
}

.select-code-column {
    min-width: 300px;
}

.ux-balance .user-management-table table th:first-child {
    min-width: 400px;
}
