@use '../01_settings/config.scss' as *;
/* button container */
.button-container {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.remitter-button-container {
  padding-top: 6px;
}