@use '../01_settings/config.scss' as *;

.mirror-main-container {
  margin-bottom: 32px;
  margin-top: 24px;
}

.ux-balance .stack>.gap-top-8 {
  margin-top: 10px;
}

.mirror-highlights-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.heading-transaction {
  margin: 0 0 16px 6px;
}

.gap-focus {
  margin-bottom: 4px;
}

.mirror-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    margin-left: 56px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.info-popover-container {
  display: flex;
  align-items: center;

  p {
    margin-right: 0.5rem;
  }
}

.popover.left-8 button {
  padding: 8px;
}

.transaction-details {
  display: flex;

  p {
    margin-left: 56px;

    &:first-child {
      margin-left: 0;
    }
  }
}