@use '../01_settings/config.scss' as *;

.custom-loader-hidden {
    display: none;
}

.custom-loader-visible {
    display: block;
}

.loader-section {
    min-height: 80px;
}

.ux-balance .loader {
    z-index: 1;
}