@use '../01_settings/config.scss' as *;
.image-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.image-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
}
.image-viewer-buttons {
    display: inline-flex;
}
.image-enlarge-button {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 8px;
}
.image-enlarge-icon {
    padding-left: 4px;
}
.enlarge-text {
    @include text-style-body-3;
    color: $color-gray-50;
}
.current-image-section {
    @include text-style-body-3;
    color: $color-gray-50;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 2px;
}

.prev-next-buttons button,
.prev-next-buttons button span {
    @include text-style-body-3;
    font-size: 1rem;
    font-weight: 600;
    color: $color-teal-50;

    &--disabled button {
        box-shadow: none;
        color: #707070;
        cursor: not-allowed;
    }
}
.close-enlarge {
    display: flex;
    justify-content: flex-end;
}
.image-footer {
    margin-top: 16px;
}
.small-image {
    height: 400px;
    width: 100%;
    object-fit: cover;
}
.full-image {
    width: 100%;
    max-height: auto;
    height: auto;
    clip-path: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
}