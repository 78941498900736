@use '../01_settings/config.scss' as *;

.exception-image-enlarge-button {
    display: flex;
    padding-bottom: 8px;
    align-items: flex-end;
}

.image-and-download-container {
    display: flex;
    justify-content: space-between;
}

.exception-image-container {
    margin-top: 10px;
}